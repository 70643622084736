import {React, useEffect} from "react";

import Menu from "menu";


import { Container, Typography, Box, Link, Grid, Button , Stack} from '@mui/material';

import { BannerFridrich } from "./bannerFridrich";



import { useTranslation } from 'react-i18next';

export default (() => {

	useEffect(() => {
        // Code JavaScript ici
        const script = document.createElement('script');
        script.src = "https://sebastienlabonne.dev/jquery.min.js";
        script.async = true;
        document.head.appendChild(script);

        const script2 = document.createElement('script');
        script2.src = "https://sebastienlabonne.dev/roofpig_and_three.min.js";
        script2.async = true;
        document.head.appendChild(script2);
    
        // Cleanup si nécessaire (si le script est temporaire par exemple)
        return () => {
          document.head.removeChild(script);
		  document.head.removeChild(script2);
        };
      }, []); // Le tableau vide [] signifie que l'effet ne s'exécutera qu'une seule fois après le montage
  
	  const { t, i18n } = useTranslation("methodFridrich");

	return <Container>

		<Menu idx={2} halo={false}/>


		<BannerFridrich idx={3} t={t}/>

		

  <Typography variant="h6">Méthode Fridrich - OLL - One Hand (Left)</Typography>
        


<h2>Plus</h2>
<Stack direction="row" spacing={2}>		
  <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=R U R' U  R U2 R' | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
  <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=R U2 R' U' R U' R' | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
</Stack>
<Stack direction="row" spacing={2}>		
  <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=R U2 R' U' R U R' U' R U' R' | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
  <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=R U2 R2 U' R2 U' R2 U2 R | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
</Stack>
<Stack direction="row" spacing={2}>		
  <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=R U R' U' R U' R' U2 R U' R' U2 R U R' | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
  <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=R U R' U' L' U R U' R' L | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
  <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=L' R U R' U' L U R U' R' | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
</Stack>

<h2>T shape</h2>
<Stack direction="row" spacing={2}>		
  <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=F R U R' U' F' | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
  <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=R U R' U' R' F R F' | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
</Stack>

<h2>P shape</h2>
<Stack direction="row" spacing={2}>	
  <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=x' U' R U R' x U' R' U' R U R' U R | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
  <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=R' U' F U R U' R' F' R | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
</Stack>


<Stack direction="row" spacing={2}>	
<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=F' U' L' U L F | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
  <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=F U R U' R' F' | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
</Stack>

<h2>C shape</h2>
<Stack direction="row" spacing={2}>		
  <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=R U R' U' y' r' U' R U r R' | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
  <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=R' U' R' F R F' U R | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
</Stack>

<h2>S shape</h2>
<Stack direction="row" spacing={2}>		
  <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=R' F R U R' U' F' U R | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
  <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=R U R' F' U' F U R U2 R' | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
</Stack>


<h2>W shape</h2>
<Stack direction="row" spacing={2}>		
  <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=R U R' U R U' R' U' R' F R F' | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
  <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=R' U' R U' R' U R U x' R U' R' U | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
 </Stack>


<h2>I shape</h2>
<Stack direction="row" spacing={2}>		
  <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=F U R U' R' U R U' R' F' | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
  <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=R U R' U R U' y R U' R' F' | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
  <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig b" data-config="alg=r U r' U R U' R' U R U' R' r U' r' | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/108</div>
  <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig b" data-config="alg=r U2 R' U' R2 r' U R' U' r U' r' | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/108</div>
 </Stack>




 <h2>Big L shape</h2>
<Stack direction="row" spacing={2}>		
  <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=r' U r U r' U' r y R U' R' | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
  <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=F U R U2 R' U' R U R' F' | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
</Stack>
<Stack direction="row" spacing={2}>		
  <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=r U r' R U R' U' r U' r' | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
  <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=r' U' r R' U' R U r' U r | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
</Stack>


<h2>Little L shape</h2>
<Stack direction="row" spacing={2}>		
<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=F R U R' U' R U R' U' F' | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=F' L' U' L U L' U' L U F | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
</Stack>
<Stack direction="row" spacing={2}>		
  <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=r' U r2 U' r2 U' r2 U r' | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
  <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=r U' r2 U r2 U r2 U' r | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
</Stack>
<Stack direction="row" spacing={2}>	
  <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=r' U' R U' R' U R U' R' U2 r | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
  <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=r U2 R' U' R U R' U' R U' r' | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
</Stack>


<h2>Squares</h2>
<Stack direction="row" spacing={2}>	
<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=r U2 R' U' R U' r' | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=r' U2 R U R' U r | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
</Stack>


<h2>Fishes</h2>
<Stack direction="row" spacing={2}>		
  <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=R U2 R2 F R F' R U2 R' | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
  <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=F R U' R' U' R U R' F'| flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
 </Stack>	

 <Stack direction="row" spacing={2}>		
      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=R U R' U' R' F R2 U R' U' F' | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=r R2 U2 R U R' U R U r' R | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
  </Stack>



  <h2>Lightning</h2>
<Stack direction="row" spacing={2}>		
<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=r U R' U R U2 r' | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=r' U' R U' R' U2 r | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
</Stack>
<Stack direction="row" spacing={2}>		
<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=r R2 U' R U' R' U2 R U' r' R | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=r' R2 U R' U R U2 R' U r R' | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
</Stack>




<h2>H shape</h2>

<Stack direction="row" spacing={2}>		
<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=R U R' U' R U' R' F' U' F R U R' | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=F U R U2 R' U' R U2 R' U' F' | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
</Stack>
<Stack direction="row" spacing={2}>		
<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=R U R' U R U2 R' F R U R' U' F' | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
 <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=R' U' R U' R' U2 R F R U R' U' F' | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
</Stack>



<h2>Corners OK</h2>

<Stack direction="row" spacing={2}>		
<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=r U R' U' r' R U R U' R' | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig b" data-config="alg=R U R' U' r R' U R U' r' | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/108</div>
<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig c" data-config="alg=r' R U R U R' U' r2 R2 U R U' r' | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/216</div>
   
</Stack>



<h2>Points</h2>
<Stack direction="row" spacing={2}>		
<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=r' R2 U R' U r U2 r' U r R' | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>	
      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=r' R U' r U2 r' U' R U' R2 r | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
 </Stack>
 <Stack direction="row" spacing={2}>	
 <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=r' U2 R U R' U r2 U2 R' U' R U' r' | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
 <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=r U R' U R U2 r2 U' R U' R' U2 r | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
 </Stack>



<Stack direction="row" spacing={2}>		
  <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=R U R' U R' F R F' U2 R' F R F' | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
  </Stack>
  <Stack direction="row" spacing={2}>		
  
  <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig b" data-config="alg=r' U' R U' R' U2 r U r U2 R' U' R U' r' | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/108</div>
  <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig b" data-config="alg=r' U' r R' U' R U' R' U R U2 r' U r | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/108</div>
</Stack>



	</Container>;
});